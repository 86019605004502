
























































































import { Component, Vue } from "vue-property-decorator";
import cardBox from "@/components/card-box/index.vue";
import pagination from "@/components/pagination/index.vue";
import { PageNum } from "@/tool/interface-index";
import {
  delResourceTemplate,
  delResourceTemplateProfile,
  getResourceTemplateList,
} from "@/api/testPaper";
import { ResourceTemplate } from "@/tool/_class";
import { UserModule } from "@/store/modules/user";

@Component({
  name: "ResourceTemplateListPage",
  components: {
    cardBox,
    pagination,
  },
})
export default class extends Vue {
  private query: {
    resourceTemplateName: string;
    keyWords: string;
  } = {
    resourceTemplateName: "",
    keyWords: "",
  };
  private pageNum: PageNum = {
    totalPage: 0,
    curPage: 1,
    pageSize: 10,
  };
  private tableData: ResourceTemplate[] = [];
  private multipleSelection: ResourceTemplate[] = [];

  private get disabledOperation(): boolean {
    console.log(`roles:${UserModule.roles}`);
    if (this.$route.meta.roles) {
      let roled: boolean = false;
      for (let i = 0; i < this.$route.meta.roles.length; i++) {
        roled =
          roled ||
          UserModule.roles.some((item) => item == this.$route.meta.roles[i]);
        if (roled) {
          break;
        }
      }
      if (!roled) {
        return true;
      }
      console.log(`meta.roles:${this.$route.meta.roles},roled:${roled}`);

      if (
        UserModule.roles.some(
          (item) => item == "admin" || item == "contentAdmin"
        )
      ) {
        return false;
      }

      /*
      return (
        UserModule.roles.findIndex((role: string) => {
          return role.toLowerCase() == "teacher";
        }) >= 0
      );
      */
    }
    return true;
  }
  handleSelectionChange(val: ResourceTemplate[]) {
    this.multipleSelection = val;
  }

  private queryResourceTemplateClick() {
    this.pageNum.curPage = 1;
    this.refPageList();
  }
  private async doDeleleResourceTemplateClick(resourceTemplateId: string) {
    await this.$confirm("删除模板，是否继续？", "提示", {
      type: "warning",
    });
    await delResourceTemplateProfile(resourceTemplateId);
    const idx = this.tableData.findIndex((template) => {
      return template.resourceTemplateId == resourceTemplateId;
    });
    this.tableData.splice(idx, 1);
  }
  private editorResourceTemplate(
    resourceTemplateId: string,
    actionType: string
  ) {
    this.$router.push({
      path: "resourceTemplateEditor",
      query: {
        resourceTemplateId,
        type: actionType,
      },
    });
  }
  private upDataPage() {
    this.refPageList();
  }
  private loading: boolean = false;
  private async refPageList() {
    try {
      this.loading = true;
      const res = await getResourceTemplateList({
        resourceTemplateIds:
          (this.$route.query.resourceTemplateIds as string[]) || [],
        resourceTemplateName: this.query!.resourceTemplateName || "",
        keyWords: this.query!.keyWords || "",
        curPage: this.pageNum.curPage,
        pageSize: this.pageNum.pageSize,
      });
      this.tableData = res.data.items;
      this.pageNum.totalPage = res.data.totalPage;
      this.pageNum.curPage = res.data.curPage;
      this.pageNum.pageSize = res.data.pageSize;
    } finally {
      this.loading = false;
    }
  }

  private async __init() {}
  activated() {
    this.__init();
    this.refPageList();
  }
  mounted() {}
}
